import {
  useMutation,
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import axiosInstance from '../services/axiosInstance';

// Fetch TikTok video URL for users
export const useGetTikTokVideoUrl = (): UseQueryResult<
  { url: string },
  Error
> => {
  return useQuery({
    queryKey: ['tiktokVideoUrl'],
    queryFn: async () => {
      const { data } = await axiosInstance.get('/api/tiktok-video');
      return data;
    },
  });
};

// Admin hook to update the TikTok video URL
export const useUpdateTikTokVideoUrl = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (url: string) => {
      const { data } = await axiosInstance.put('/api/tiktok-video', { url });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tiktokVideoUrl'] });
    },
  });
};
