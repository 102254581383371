import React, { useState } from 'react';
import {
  useGetTikTokVideoUrl,
  useUpdateTikTokVideoUrl,
} from '../hooks/tiktokVideo';

const TikTokVideoAdmin: React.FC = () => {
  const { data, isLoading } = useGetTikTokVideoUrl();
  const updateMutation = useUpdateTikTokVideoUrl();

  const [url, setUrl] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState('');

  // Set the current URL in the input field when data is loaded
  React.useEffect(() => {
    if (data && data.url) {
      setUrl(data.url);
    }
  }, [data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsUpdating(true);
      await updateMutation.mutateAsync(url);
      setMessage('TikTok video URL updated successfully');
    } catch (error) {
      setMessage('Error updating TikTok video URL');
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h2 className="text-xl font-bold mb-4">Update TikTok Video</h2>

      {message && (
        <div
          className={`p-3 mb-4 ${
            message.includes('Error')
              ? 'bg-red-100 text-red-700'
              : 'bg-green-100 text-green-700'
          }`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="url" className="block mb-2 font-medium">
            TikTok Embed URL
          </label>
          <input
            type="text"
            id="url"
            className="w-full p-2 border rounded"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://www.tiktok.com/embed/7475195016340622634"
            required
          />
          <p className="text-sm text-gray-500 mt-1">
            Format: https://www.tiktok.com/embed/VIDEOID
          </p>
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded"
          disabled={isUpdating}
        >
          {isUpdating ? 'Updating...' : 'Update Video URL'}
        </button>
      </form>

      {data && data.url && (
        <div className="mt-6">
          <h3 className="font-bold mb-2">Current Video:</h3>
          <div className="aspect-video">
            <iframe
              src={`${data.url}?autoplay=0&muted=1`}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[750px] rounded"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default TikTokVideoAdmin;
